export default defineNuxtRouteMiddleware((to, from) => {
  const headers = useRequestHeaders()
  const referer = headers['referer'] || 'origin'
  useState('referrerState', () => referer) // store the referrer in a state server-side

  if (from.path === '/bestellbestaetigung' && to.path !== '/') {
    return navigateTo({ path: '/' })
  }
  if (to.path === '/bestellbestaetigung' && from.path !== '/kasse') {
    return navigateTo({ path: '/' })
  }
  if (to.path !== to.path.toLowerCase()) {
    return navigateTo({ path: to.path.toLowerCase() })
  }
})
